(function ($) {
  $(document).ready(function () {
    svg4everybody({});
  });
})(jQuery);

// MODAL CLASS ==================================================
class Modal {
  constructor(options = {}) {
    if (options.modalSelector && options.triggerSelector) {
      this.modal = document.querySelectorAll(options.modalSelector) || null;
      this.trigger = document.querySelectorAll(options.triggerSelector) || null;
      this.closeBtn = options.closeSelector || null;
      this.modalActiveClass = options.modalActiveClass || 'show';
      this.scrollbarWidht = this.calcScroll();
      this.disableScrollbar = options.disableScrollbar !== undefined ? options.disableScrollbar : true;
      this.closeOnLightbox = options.closeOnLightbox !== undefined ? options.closeOnLightbox : true;
      this.closeOnEsc = options.closeOnEsc !== undefined ? options.closeOnEsc : true;
      this.bodyChangedWidth = false;
      this.on =
        {
          beforeInit: options.on && options.on.beforeInit ? options.on.beforeInit.bind(this.on) : null,
          init: options.on && options.on.init ? options.on.init.bind(this.on) : null,
          beforeOpen: options.on && options.on.beforeOpen ? options.on.beforeOpen.bind(this.on) : null,
          afterOpen: options.on && options.on.afterOpen ? options.on.afterOpen.bind(this.on) : null,
          beforeClose: options.on && options.on.beforeClose ? options.on.beforeClose.bind(this.on) : null,
          afterClose: options.on && options.on.afterClose ? options.on.afterClose.bind(this.on) : null
        } || {};

      if (this.modal.length && this.trigger.length) {
        this.init();
      }
    }
  }

  init = () => {
    this.on.beforeInit && this.on.beforeInit(this);
    this.trigger.forEach((btn) => btn.addEventListener('click', this.showModal));
    this.modal.forEach((modal) => {
      if (this.closeBtn) {
        modal.querySelectorAll(this.closeBtn).forEach((btn) => (btn.onclick = () => this.closeModal(modal)));
      }
      if (this.closeOnLightbox)
        modal.onclick = (e) => {
          if (e.target === modal) this.closeModal(modal);
        };
    });
    this.on.init && this.on.init(this);
  };

  showModal = (e) => {
    this.modal.forEach((modal) => {
      if (modal.id.toLowerCase() === e.currentTarget.dataset.modalTarget.toLowerCase()) {
        this.on.beforeOpen && this.on.beforeOpen(this, modal, e.currentTarget);
        modal.classList.add(this.modalActiveClass);
        this.disableScroll();
        if (this.closeOnEsc)
          window.onkeydown = (e) => {
            if (e.code === 'Escape') this.closeModal(modal);
          };
        this.on.afterOpen && this.on.afterOpen(this, modal, e.currentTarget);
      } else {
        if (modal.classList.contains(this.modalActiveClass)) {
          this.closeModal(modal, e.currentTarget);
        }
      }
    });
  };

  closeModal = (modal, trigger) => {
    this.on.beforeClose && this.on.beforeClose(this, modal, trigger);
    modal.classList.remove(this.modalActiveClass);
    this.enableScroll();
    this.on.afterClose && this.on.afterClose(this, modal, trigger);
  };

  disableScroll() {
    const body = document.body;
    body.style.overflow = 'hidden';
    if (Math.round(body.getBoundingClientRect().height) > window.innerHeight && this.disableScrollbar) {
      body.style.paddingRight = `${this.scrollbarWidht}px`;
      this.bodyChangedWidth = true;
    }
  }

  enableScroll() {
    const body = document.body;

    body.style.overflow = 'auto';
    if (this.bodyChangedWidth && this.disableScrollbar) {
      body.style.paddingRight = '0px';
      this.bodyChangedWidth = false;
    }
  }

  calcScroll() {
    let div = document.createElement('div');
    div.style.visibility = 'hidden';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.overflowY = 'scroll';
    document.body.appendChild(div);
    let scrollWidth = div.offsetWidth - div.clientWidth;
    div.remove();
    return scrollWidth;
  }
}
// MODAL CLASS end ==================================================

// SLIDE FUNCTIONS ==================================================
const slideUp = (target, duration = 500) => {
  target.style.transitionProperty = `height, margin, padding`;
  target.style.transitionDuration = duration + `ms`;
  target.style.boxSizing = `border-box`;
  target.style.height = target.offsetHeight + `px`;
  target.offsetHeight;
  target.style.overflow = `hidden`;
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(() => {
    target.style.display = `none`;
    target.style.removeProperty(`height`);
    target.style.removeProperty(`padding-top`);
    target.style.removeProperty(`padding-bottom`);
    target.style.removeProperty(`margin-top`);
    target.style.removeProperty(`margin-bottom`);
    target.style.removeProperty(`overflow`);
    target.style.removeProperty(`transition-duration`);
    target.style.removeProperty(`transition-property`);
  }, duration);
};

const slideDown = (target, duration = 500) => {
  target.style.removeProperty(`display`);
  let display = window.getComputedStyle(target).display;
  if (display === `none`) {
    display = `block`;
  }
  target.style.display = display;
  const height = target.offsetHeight;
  target.style.overflow = `hidden`;
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = `border-box`;
  target.style.transitionProperty = `height, margin, padding, opacity`;
  target.style.transitionDuration = duration + `ms`;
  target.style.height = height + `px`;
  target.style.removeProperty(`padding-top`);
  target.style.removeProperty(`padding-bottom`);
  target.style.removeProperty(`margin-top`);
  target.style.removeProperty(`margin-bottom`);
  window.setTimeout(() => {
    target.style.removeProperty(`height`);
    target.style.removeProperty(`overflow`);
    target.style.removeProperty(`transition-duration`);
    target.style.removeProperty(`transition-property`);
  }, duration);
};

const slideToggle = (target, duration = 500) => {
  if (window.getComputedStyle(target).display === `none`) {
    return slideDown(target, duration);
  } else {
    return slideUp(target, duration);
  }
};
// SLIDE FUNCTIONS END ==================================================

// Utils
const createEl = (element, ...classNames) => {
  const newElement = document.createElement(element);

  if (classNames.length) {
    newElement.classList.add([...classNames]);
  }

  return newElement;
};

// set vh height
window.addEventListener('resize', function () {
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
// set vh height end

// Get scroll width
const calcScroll = () => {
  let div = document.createElement('div');
  div.style.visibility = 'hidden';
  div.style.width = '50px';
  div.style.height = '50px';
  div.style.overflowY = 'scroll';
  document.body.appendChild(div);
  let scrollWidth = div.offsetWidth - div.clientWidth;
  div.remove();
  return scrollWidth;
};
// Get scroll width end

window.addEventListener('load', function () {
  let body = this.document.querySelector('body');
  let html = this.document.querySelector('html');

  // set vh height
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // set vh height end

  // set vh height
  const setVhProp = () => {
    const headerHeight = document.querySelector('.header').getBoundingClientRect().height;
    document.documentElement.style.setProperty('--header-height', ''.concat(headerHeight, 'px'));
  };
  // set vh height end

  // Libs init ======================================================
  const simpleBarContainers = document.querySelectorAll('[data-simplebar]');

  simpleBarContainers.length && simpleBarContainers.forEach((container) => new SimpleBar(container));

  new Modal({
    modalSelector: '[data-modal]',
    triggerSelector: '[data-modal-trigger]',
    closeSelector: '[data-modal-close]',
    modalActiveClass: 'show',
    closeOnLightbox: true,
    closeOnEsc: true,
    disableScrollbarElement: true,
    on: {
      afterOpen: (instance, modal, trigger) => {
        const header = document.querySelector('.header');

        if (header) header.style.paddingRight = `${instance.scrollbarWidht}px`;
        if (modal.id === 'search') header.classList.add('hide');
      },
      afterClose: (instance, modal, trigger) => {
        const header = document.querySelector('.header');

        if (header) header.style.paddingRight = '0px';
        if (modal.id === 'search') header.classList.remove('hide');
      }
    }
  });
  // Libs init end ======================================================

  // HEADER MENU ======================================================
  let headerMenu = document.querySelector('.header__menu');
  let headerBurger = document.querySelector('.header__burger');
  let wrapper = this.document.querySelector('.wrapper');
  // header scroll
  let header = document.querySelector('.header');
  const scrollHeader = () => {
    if (!header) return;

    if (window.scrollY > 0) {
      header.classList.add('active');
      if (wrapper) {
        wrapper.classList.add('active');
      }
    } else {
      header.classList.remove('active');
      if (wrapper) {
        wrapper.classList.remove('active');
      }
    }
  };
  // header scroll end

  if (headerMenu && headerBurger) {
    let closeMenu = function () {
      headerMenu.classList.remove('active');
      headerBurger.classList.remove('active');
      body.classList.remove('lock');
      html.classList.remove('lock');
      if (header) {
        if (window.scrollY < 1 && header.classList.contains('active')) {
          header.classList.remove('active');
          if (wrapper) {
            wrapper.classList.remove('active');
          }
        }
      }
    };
    headerBurger.addEventListener('click', function () {
      const scrollbarWidth = calcScroll();
      if (window.innerWidth >= 1025 && !headerMenu.classList.contains('active')) {
        header.querySelector('.header-container').style.marginRight = scrollbarWidth + 'px';
        document.body.style.paddingRight = scrollbarWidth + 'px';
      } else if (window.innerWidth >= 1025 && headerMenu.classList.contains('active')) {
        header.querySelector('.header-container').style.marginRight = '0px';
        document.body.style.paddingRight = '0px';
      }
      headerMenu.classList.toggle('active');
      headerBurger.classList.toggle('active');
      body.classList.toggle('lock');
      html.classList.toggle('lock');
      if (header) {
        if (window.scrollY < 1 && !header.classList.contains('active')) {
          header.classList.add('active');
          if (wrapper) {
            wrapper.classList.add('active');
          }
        } else if (window.scrollY < 1 && header.classList.contains('active')) {
          header.classList.remove('active');
          if (wrapper) {
            wrapper.classList.remove('active');
          }
        }
      }
    });

    window.addEventListener('resize', function () {
      if (window.innerWidth > 1024 && headerMenu.classList.contains('active')) {
        closeMenu();
      }
    });

    window.addEventListener('click', function (e) {
      if (e.target.classList.contains('header__menu-wrapp')) {
        closeMenu();
      }
    });
  }
  // HEADER MENU END ======================================================

  // SELECT ==============================================================
  function closeSelect() {
    selects.forEach((item) => {
      if (item.classList.contains('active')) {
        item.classList.remove('active');
      }
    });
    dropDowns.forEach((item) => {
      if (item.classList.contains('active')) {
        item.classList.remove('active');
        slideUp(item, 100);
      }
    });
  }
  const selectsInside = document.querySelector('.selects-inside');
  const selectTrigger = document.querySelectorAll('.select__trigger');
  const selects = document.querySelectorAll('.selecter');
  const dropDowns = document.querySelectorAll('.select__dropdown');
  const dropDownOption = document.querySelectorAll('.select__option');
  selectTrigger &&
    selectTrigger.forEach((item) => {
      item.addEventListener('click', function () {
        const container = this.closest('.selecter');
        const dropdown = container.querySelector('.select__dropdown');
        const selectsItems = [...selects].filter((item) => item !== container);
        const dropDownsItems = [...dropDowns].filter((item) => item !== dropdown);
        selectsItems.forEach((item) => {
          item.classList.remove('active');
        });
        dropDownsItems.forEach((item) => {
          item.classList.remove('active');
          slideUp(item, 100);
        });
        // closeSelect()
        if (!container.classList.contains('active')) {
          container.classList.add('active');
          dropdown && dropdown.classList.add('active');
          slideDown(dropdown, 100);
        } else {
          container.classList.remove('active');
          dropdown && dropdown.classList.remove('active');
          slideUp(dropdown, 100);
        }
      });
    });

  selectsInside &&
    selectsInside.addEventListener('click', function (e) {
      const target = e.target;
      if (!target.closest('.selecter')) {
        closeSelect();
      }
    });

  dropDownOption &&
    dropDownOption.forEach((item) => {
      item.addEventListener('click', function () {
        const container = this.closest('.selecter');
        const valueItem = container.querySelector('.select__value');
        const input = container.querySelector('.select__input');
        const options = container.querySelectorAll('.select__option');
        const formItem = this.closest('.form-item');
        const trigger = container.querySelector('.select__trigger');
        if (trigger.classList.contains('placeholder')) {
          trigger.classList.remove('placeholder');
        }
        if (formItem) {
          if (formItem.classList.contains('error')) {
            formItem.classList.remove('error');
          }
        }
        const value = this.getAttribute('data-value');
        if (valueItem) {
          valueItem.innerHTML = value;
        }
        input.value = value;
        options.forEach((item) => {
          item.classList.remove('active');
        });
        this.classList.add('active');
        closeSelect();
      });
    });
  // SELECT END ==============================================================

  // TABS =================================================================
  let globalTabs = document.querySelectorAll('.global-tabs');
  if (globalTabs) {
    globalTabs.forEach((tabContainer) => {
      let tabButtons = tabContainer.querySelectorAll('.global-tabs-btn');
      let tabBoxes = tabContainer.querySelectorAll('.global-search__content');
      if (tabButtons && tabBoxes) {
        tabButtons.forEach((tabBtn) => {
          tabBtn.addEventListener('click', function () {
            // find active
            if (!tabBtn.classList.contains('active')) {
              // remove active
              let activeBtn = tabContainer.querySelector('.global-tabs-btn.active');
              let activeTabBox = tabContainer.querySelector('.global-search__content.active');
              if (activeBtn) {
                activeBtn.classList.remove('active');
              }
              if (activeTabBox) {
                activeTabBox.classList.remove('active');
              }
              // add current
              tabBoxes.forEach((tabBox) => {
                if (
                  tabBtn.getAttribute('data-tab').toLocaleLowerCase() ==
                  tabBox.getAttribute('data-tab').toLocaleLowerCase()
                ) {
                  tabBtn.classList.add('active');
                  tabBox.classList.add('active');
                }
              });
            }
          });
        });
      }
    });
  }
  // TABS END =============================================================

  // FLATPICKR ===============================================================
  let flatpickrInputs = document.querySelectorAll('.flatpickr-field');
  if (flatpickrInputs) {
    flatpickrInputs.forEach((picker) => {
      let pickerElement = flatpickr(picker, {
        dateFormat: 'd-m-Y',
        minDate: 'today',
        disableMobile: 'false',
        onChange: function () {
          if (picker.classList.contains('flatpickr-field-from')) {
            let rangeBlock = picker.closest('.pickr-range-fields');
            if (rangeBlock) {
              let rangeToField = rangeBlock.querySelector('.flatpickr-field-to');
              if (rangeToField) {
                if (rangeToField.value) {
                  rangeToField.value = picker.value;
                }
                flatpickr(rangeToField, {
                  dateFormat: 'd-m-Y',
                  minDate: picker.value
                });
              }
            }
          }
        }
      });
    });
  }
  // FLATPICKR END ===============================================================

  // REVIEWS SLIDER ======================================================
  let reviewsSlider = new Swiper('.reviews-slider .swiper-container', {
    speed: 700,
    slidesPerView: 1,
    spaceBetween: 20,
    grabCursor: true,
    navigation: {
      nextEl: '.reviews-slider .global-slider-btn-next',
      prevEl: '.reviews-slider .global-slider-btn-prev'
    },
    breakpoints: {
      701: {
        slidesPerView: 2,
        spaceBetween: 20,
        grabCursor: true
      },
      1025: {
        slidesPerView: 1,
        spaceBetween: 10,
        grabCursor: true
      }
    }
  });
  // REVIEWS SLIDER END ==================================================

  // GALLARY SLIDERS ======================================================
  let gallerySmallSlider = new Swiper('.gallery-small__slider .swiper-container', {
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 20,
    grabCursor: true,
    breakpoints: {
      701: {
        slidesPerView: 2,
        spaceBetween: 20,
        grabCursor: true
      },
      1025: {
        slidesPerView: 2,
        spaceBetween: 30,
        grabCursor: true,
        slidesPerGroup: 2
      }
    }
  });

  let galleryBigSlider = new Swiper('.gallery-big__slider .swiper-container', {
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 20,
    grabCursor: true,
    navigation: {
      nextEl: '.gallery-big__slider .global-slider-btn-next',
      prevEl: '.gallery-big__slider .global-slider-btn-prev'
    },
    breakpoints: {
      701: {
        slidesPerView: 2,
        spaceBetween: 20,
        grabCursor: true
      },
      1025: {
        slidesPerView: 2,
        spaceBetween: 30,
        grabCursor: true,
        slidesPerGroup: 2
      }
    }
  });
  if (galleryBigSlider && gallerySmallSlider) {
    galleryBigSlider.on('slideChange', function () {
      gallerySmallSlider.slideTo(galleryBigSlider.activeIndex);
    });
    gallerySmallSlider.on('slideChange', function () {
      galleryBigSlider.slideTo(gallerySmallSlider.activeIndex);
    });
  }
  // GALLARY SLIDERS END ==================================================

  //VALIDATE FORM ======================================================
  const forms = document.querySelectorAll('.form-container');
  let timerId = null;

  const validateFileInput = (event) => {
    const { target } = event;
    if (!target.accept && !target) return;

    const fileTypes = target.accept.replace(/\,/g, '|\\');
    const fileTypesRegexp = new RegExp(`(${fileTypes})$`, 'i');
    const validFileType = target.files[0] ? fileTypesRegexp.test(target.files[0].name) : false;

    if (!validFileType) {
      event.preventDefault();
      const invalidFileTypeMessage = document.querySelector('.invalid-file-type-message');

      if (invalidFileTypeMessage) {
        invalidFileTypeMessage.querySelector('.invalid-file-type-message__formats').innerHTML = target.accept;
        invalidFileTypeMessage.classList.add('show');
        clearTimeout(timerId);
        setTimeout(() => invalidFileTypeMessage.classList.remove('show'), 3000);
      }
    }

    return validFileType;
  };

  forms &&
    forms.forEach((item) => {
      // email patern
      let email = item.querySelector('.mail');
      if (email) {
        let parent = email.closest('.form-item');
        let pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
        email.addEventListener('keyup', function (ev) {
          let value = email.value;
          if (value.match(pattern)) {
            parent.classList.remove('error__email');
          } else {
            parent.classList.add('error__email');
          }
          if (value.length < 1) {
            parent.classList.remove('error__email');
          }
        });
      }
      // email patern end

      // Name
      const nameInputs = item.querySelectorAll('.name');
      if (nameInputs.length) {
        nameInputs.forEach((input) =>
          input.addEventListener('input', ({ target }) => {
            target.value = target.value.replace(/[^a-z A-Z а-я А-я Ї ї І і ]/g, '');
          })
        );
      }
      // Name end

      // number
      const numberInputs = item.querySelectorAll('.phone');
      if (numberInputs.length) {
        numberInputs.forEach((input) => {
          let validNumber = false;

          input.addEventListener('input', ({ target }) => {
            const { value, maxLength, minLength } = input;
            target.value = value.replace(/[^0-9() +-]/g, '');
            let parent = target.closest('.form-item');

            validNumber = value.length <= maxLength && value.length + 1 > minLength;
            if (validNumber) {
              parent.classList.remove('error__phone');
            } else {
              parent.classList.add('error__phone');
            }
          });
        });
      }
      // number end

      item.addEventListener('submit', function (e) {
        e.preventDefault();
        let valide = true;
        const inputs = item.querySelectorAll('.form-required');

        // checkbox valid
        let checkbox = item.querySelector('.form__checkbox input');
        let checkboxParent = item.querySelector('.form__checkbox');
        if (checkbox) {
          valide = false;
          if (checkbox.checked) {
            valide = true;
            checkboxParent.classList.remove('error');
          } else {
            checkboxParent.classList.add('error');
            valide = false;
          }
        }
        // checkbox valid end

        // empty fields valid
        inputs.forEach((element) => {
          const parent = element.closest('.form-item');
          if (element.value.trim() === '') {
            parent.classList.add('error');
            valide = false;
          } else {
            parent.classList.remove('error');
          }
        });
        // empty fields valid end

        // email validate
        let emailField = item.querySelector('.mail');
        if (email) {
          let emailParent = emailField.closest('.form-item');
          if (emailParent && emailParent.classList.contains('error__email')) {
            valide = false;
          }
        }
        // email validate end

        if (valide) {
          fetch('https://jsonplaceholder.typicode.com/posts', {
            method: 'POST',
            body: new FormData(item)
          })
            .then(function (response) {
              if (response.ok) {
                return response.json();
              }
              return Promise.reject(response);
            })
            .then(function (data) {})
            ['catch'](function (error) {
              console.warn(error);
            });

          // clear on sent
          let allInputs = item.querySelectorAll('.form__area');
          if (allInputs) {
            allInputs.forEach((el) => {
              el.value = '';
            });
          }
          // clear on sent end

          // clear checkbox
          let checkboxInput = item.querySelectorAll('.form__checkbox input');
          if (checkboxInput) {
            checkboxInput.forEach((checkForm) => {
              checkForm.checked = false;
            });
          }
          // clear checkbox end

          // clear dropdowns
          let dropdownForm = item.querySelectorAll('.select-info.selecter');
          if (dropdownForm) {
            dropdownForm.forEach((dropForm) => {
              let valueHolder = dropForm.querySelector('.select__value');
              let inputDrop = dropForm.querySelector('input');
              if (valueHolder && dropForm.hasAttribute('data-holder')) {
                valueHolder.innerHTML = dropForm.getAttribute('data-holder');
              }
              if (inputDrop) {
                inputDrop.value = '';
              }
            });
          }
          // clear dropdowns end

          // clear files
          let filesForm = item.querySelector('.form-file-label');
          if (filesForm) {
            let btnFile = filesForm.querySelector('.file-label-btn');
            if (btnFile) {
              btnFile.classList.remove('filled');
            }
            let inputFile = filesForm.querySelector('input');
            if (inputFile) {
              inputFile.value = '';
            }
          }
          // clear files end
        }
      });
    });
  // clear on input
  const inputsField = document.querySelectorAll('.form-container .form-item');
  inputsField.forEach((field) => {
    let inputField = field.querySelector('input');
    if (inputField) {
      inputField.addEventListener('input', function () {
        field.classList.remove('error');
      });
    }
  });
  // clear on input end

  // Files attachment
  const filesAttachment = document.querySelector('[data-files-container]');

  if (filesAttachment) {
    const fileInput = filesAttachment.querySelector('[data-file-input]');

    const markup = (fileInput, fileName, firstFiles) => {
      const attachedIcon = `
      <svg class="icon icon-attached-file ">
        <use href="icon/icons/icons.svg#attached-file"></use>
      </svg>`;

      const chengeBtn = `<label class="profile-form__attached-file-btn change">
      <svg class="icon icon-change ">
        <use href="icon/icons/icons.svg#change"></use>
      </svg>
      </label>`;

      const removeBtn = `<button class="profile-form__attached-file-btn remove" type="button">
    <svg class="icon icon-bucket ">
      <use href="icon/icons/icons.svg#bucket"></use>
    </svg>
      </button>`;

      // File name
      const fileNameBox = createEl('span');
      fileNameBox.innerHTML = fileName;

      // Attached file box
      const attachFileWrap = createEl('div', 'profile-form__attached-file');
      attachFileWrap.insertAdjacentHTML('afterbegin', attachedIcon);
      attachFileWrap.appendChild(fileNameBox);
      attachFileWrap.insertAdjacentHTML('beforeend', chengeBtn);
      attachFileWrap.insertAdjacentHTML('beforeend', removeBtn);

      // Attached file container
      const col = createEl('div', 'profile-form__files-col');
      col.appendChild(attachFileWrap);

      // Attached file buttons
      const attachedFileChangeBtn = col.querySelector('.change');
      const attachedFileRemoveBtn = col.querySelector('.remove');
      attachedFileChangeBtn.appendChild(fileInput);

      fileInput.addEventListener('input', (event) => {
        const { target } = event;

        if (!validateFileInput(event)) {
          target.files = firstFiles;
          fileNameBox.innerHTML = firstFiles[0].name;
          return;
        }

        if (!target.files[0]) {
          target.files = firstFiles;
          fileNameBox.innerHTML = firstFiles[0].name;
          return;
        }

        firstFiles = target.files;
        fileNameBox.innerHTML = target.files[0].name;
      });

      attachedFileRemoveBtn.addEventListener('click', ({ target }) => {
        target.closest('.profile-form__files-col').remove();
      });

      return col;
    };

    fileInput.addEventListener('change', (event) => {
      const { target } = event;
      const { files, dataset, accept } = target;

      if (!validateFileInput(event)) {
        target.value = '';
        return;
      }

      const count = +dataset.counter + 1;

      const fileInput = createEl('input');
      fileInput.name = `${dataset.name}_${count}`;
      fileInput.type = 'file';
      fileInput.files = files;
      fileInput.accept = accept;
      filesAttachment.prepend(markup(fileInput, files[0].name, files));

      target.name = `${dataset.name}_${count}`;
      target.dataset.counter = count;
    });
  }
  // Files attachment end
  // VALIDATE FORM END  ======================================================

  // Common - sidebar ion-range-slider
  const ionRangeInputPrice = document.querySelector('[data-ion-range-price]');
  const ionRangeInputDuration = document.querySelector('[data-ion-range-duration]');
  $('#range-price').ionRangeSlider({
    type: 'double',
    min: ionRangeInputPrice && ionRangeInputPrice.dataset.priceMin,
    max: ionRangeInputPrice && ionRangeInputPrice.dataset.priceMax,
    from: 0,
    to: ionRangeInputPrice && ionRangeInputPrice.dataset.priceMax,
    onChange(e) {
      rangeInputsValue(e, ionRangeInputPrice && ionRangeInputPrice);
    },
    onStart(e) {
      rangeInputsValue(e, ionRangeInputPrice && ionRangeInputPrice);
    }
  });
  $('#range-duration').ionRangeSlider({
    type: 'double',
    min: ionRangeInputDuration && ionRangeInputDuration.dataset.durationMin,
    max: ionRangeInputDuration && ionRangeInputDuration.dataset.durationMax,
    from: 0,
    to: ionRangeInputDuration && ionRangeInputDuration.dataset.durationMax,
    onChange(e) {
      rangeInputsValue(e, ionRangeInputDuration && ionRangeInputDuration);
    },
    onStart(e) {
      rangeInputsValue(e, ionRangeInputDuration && ionRangeInputDuration);
    }
  });

  function rangeInputsValue(e, input) {
    input.value = e.from;
    input.value = e.to;
  }

  // Common - sidebar ion-range-slider end

  // Catalog filte
  const filter = this.document.querySelector('.catalog-filter');
  const filterTrigger = document.querySelector('.catalog-filter__trigger');
  const filterBody = document.querySelector('.catalog-filter__body');
  const filterClose = filterBody && filterBody.querySelector('.catalog-filter__cancel');
  let filterOpenTimerId = null;
  let filterCloseTimerId = null;
  let filterIsOpening = false;
  let filterIsClosing = false;

  const filterCloseHandle = () => {
    slideUp(filterBody, 400);
    filterTrigger.classList.remove('active');
  };

  filterTrigger &&
    filterTrigger.addEventListener('click', (e) => {
      if (filterIsClosing) return;
      slideToggle(filterBody, 400);
      filterTrigger.classList.toggle('active');
      filterTrigger.style.pointerEvents = 'none';
      filterIsOpening = true;

      clearTimeout(filterOpenTimerId);
      filterOpenTimerId = setTimeout(() => {
        filterTrigger.style.pointerEvents = 'all';
        filterIsOpening = false;
      }, 500);
    });

  filterClose &&
    filterClose.addEventListener('click', () => {
      filterCloseHandle();
    });

  window.addEventListener('click', (event) => {
    const domElemenst = event.composedPath();

    if (filter) {
      let filterClicked = false;

      domElemenst.forEach((element) => {
        if (element === filter) filterClicked = true;
      });

      if (!filterClicked && !filterIsOpening) {
        filterIsClosing = true;
        filterCloseHandle();

        clearTimeout(filterCloseTimerId);
        filterCloseTimerId = setTimeout(() => {
          filterIsClosing = false;
        }, 450);
      }
    }
  });
  filter &&
    filter.addEventListener('submit', (event) => {
      event.preventDefault();
      // get elements
      const { target } = event;
      const resultContainer = document.querySelector('.catalog-tours__filter-panel-result');
      const toursListContainer = document.querySelector('.tours-list');

      // get and filter inputs from DOM
      const inputs = target.querySelectorAll('input');
      const arrInputs = [].slice
        .call(inputs)
        .filter((input) => input.getAttribute('type') !== 'checkbox' || input.checked);

      // Reduce form data
      const data = arrInputs.reduce((acc, input) => {
        const keys = Object.keys(acc);
        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];

          if (key === input.name) {
            return {
              ...acc,
              [key]: Array.isArray(acc[key]) ? [...acc[key], input.value] : [acc[key], input.value]
            };
          }
        }

        return { ...acc, [input.name]: input.value };
      }, {});

      console.log(data);

      // Send fetch request
      fetch(target.getAttribute('action'), {
        method: target.getAttribute('method')
        // body: new FormData(target)
      })
        .then((data) => data.json())
        .then((data) => {
          if (resultContainer) {
            resultContainer.querySelector('span').innerHTML = data.count;
          }
          if (toursListContainer) {
            toursListContainer.innerHTML = data.response;
          }
        });
    });
  // Catalog filte end

  // Tour page sliders
  const tourThumbsSlider = new Swiper('.tour-banner-thumbs-slider', {
    spaceBetween: 10,
    slidesPerView: 3,
    watchSlidesProgress: true,
    breakpoints: {
      375: {
        slidesPerView: 4
      },
      500: {
        spaceBetween: 20,
        slidesPerView: 5
      },
      1150: {
        spaceBetween: 20,
        slidesPerView: 2
      },
      1250: {
        spaceBetween: 44,
        slidesPerView: 3
      },
      1500: {
        spaceBetween: 44,
        slidesPerView: 3
      }
    }
  });
  new Swiper('.tour-banner-main-slider', {
    spaceBetween: 10,
    slidesPerView: 1,
    navigation: {
      nextEl: '.tour-banner-slider-btn-next',
      prevEl: '.tour-banner-slider-btn-prev'
    },
    thumbs: {
      swiper: tourThumbsSlider
    }
  });
  // Tour page sliders end

  // Tour page indicators
  new Swiper('.indicators__silder', {
    spaceBetween: 40,
    slidesPerView: 1.5,
    breakpoints: {
      390: {
        slidesPerView: 2
      },
      600: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 4
      },
      1200: {
        slidesPerView: 6
      },
      1550: {
        spaceBetween: 70,
        slidesPerView: 6
      }
    }
  });
  // Tour page indicators end

  // Tour page accordeon
  const accordeonContainer = document.querySelector('[data-accordeon]');

  if (accordeonContainer) {
    const accordeonItems = accordeonContainer.querySelectorAll('[data-accordeon-item]');

    const itemHandle = (currentIndex) => {
      accordeonItems.forEach((item, index) => {
        if (currentIndex !== index) {
          item.classList.remove('active');
        } else {
          item.classList.toggle('active');
        }
      });
    };

    accordeonItems.length &&
      accordeonItems.forEach((item, index) => {
        const trigger = item.querySelector('[data-accordeon-trigger]');

        trigger.addEventListener('click', () => {
          itemHandle(index);
        });
      });
  }
  // Tour page accordeon end

  // Common fixed block handle
  const fixedBlockHandle = (containerSelector, disableWidthBreackpoint = 1200, disableHeightBreackpoint = 500) => {
    // Get container from DOM
    const containerEl = document.querySelector(containerSelector);
    if (!containerEl) return;

    // Get elements from container
    const header = document.querySelector('.header');
    const targetBlockEl = containerEl.querySelector('[data-fixed-block-target]');

    if (!header || !targetBlockEl) return console.warn(`Can't find some element of fixed block on scroll`);
    if (window.innerWidth <= disableWidthBreackpoint || window.innerHeight <= disableHeightBreackpoint)
      return targetBlockEl.removeAttribute('style');

    // Get position values of elements
    const headerRect = header.getBoundingClientRect();
    const containerRect = containerEl.getBoundingClientRect();
    const targetBlockRect = targetBlockEl.getBoundingClientRect();

    // Calc values of elements
    const containerOnTopOfwindow = containerRect.top - headerRect.height;
    const containerOnTopOfwindowABS = Math.abs(containerOnTopOfwindow);
    const targetOnBottomOfContainer = containerOnTopOfwindowABS + targetBlockRect.height;

    // Set properties
    if (containerOnTopOfwindow <= 0 && targetOnBottomOfContainer < containerRect.height) {
      targetBlockEl.style.top = `${containerOnTopOfwindowABS}px`;
      targetBlockEl.style.bottom = `0px`;
    } else if (targetOnBottomOfContainer >= containerRect.height) {
      targetBlockEl.style.top = `${containerRect.height - targetBlockRect.height}px`;
    } else if (containerOnTopOfwindow > 0) {
      targetBlockEl.style.top = '0px';
    }
  };
  // Common fixed block handle end

  // SEARCH =================================================================

  const searchHandle = (selectorContainer) => {
    const searchForm = document.querySelector(selectorContainer);
    if (!searchForm) return;

    const searchInput = searchForm.querySelector('[data-search-input]');
    const searchBody = searchForm.querySelector('[data-search-dropdown]');
    const searchBodyClose = searchForm.querySelector('[data-search-close]');
    // const mobileTrigger = document.querySelector('[data-search-trigger]');
    // console.log(mobileTrigger);

    // mobileTrigger.addEventListener('click', () => searchForm.classList.add('active'));

    searchInput.addEventListener('input', (event) => {
      const serarchValue = event.target.value;
      const serchLinks = searchBody.querySelectorAll('.name');

      if (event.target.value && !searchForm.classList.contains('active')) {
        searchForm.classList.add('active');
      } else if (!event.target.value && searchForm.classList.contains('active')) {
        searchForm.classList.remove('active');
      }

      if (serarchValue.length >= 2) {
        const regExp = new RegExp(serarchValue, 'gi');

        if (serchLinks.length) {
          serchLinks.forEach((link) => {
            let text = link.innerHTML;
            text = text.replace(/(<b>|<\/b>)/gim, '');
            const newText = text.replace(regExp, '<b>$&</b>');
            link.innerHTML = newText;
          });
        }
      }
    });

    searchBodyClose.addEventListener('click', () => searchForm.classList.remove('active'));

    window.addEventListener('click', (event) => {
      if (window.innerWidth <= 992) return;

      let clickedForm = false;
      let element = event.target;
      const elements = [];

      do {
        elements.push(element);
        element = element.parentNode;
      } while (element);

      elements &&
        elements.forEach((domEl) => {
          if (domEl === searchForm) {
            clickedForm = true;
          }
        });

      if (!clickedForm) {
        searchForm.classList.remove('active');
      }
    });
  };

  // Catalog search box
  const searchForm = document.querySelector('.js-catalog-search');
  const searchFormHandle = (event) => {
    event.preventDefault();
    // get elements
    const { target } = event;
    const resultContainer = document.querySelector('.catalog-tours__filter-panel-result');
    const toursListContainer = document.querySelector('.tours-list');

    // get and filter inputs from DOM
    const inputs = target.querySelectorAll('input');
    const arrInputs = [].slice.call(inputs).filter((input) => input.getAttribute('type') !== 'radio' || input.checked);

    // Reduce form data
    const data = arrInputs.reduce((acc, input) => {
      const keys = Object.keys(acc);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];

        if (key === input.name) {
          return {
            ...acc,
            [key]: Array.isArray(acc[key]) ? [...acc[key], input.value] : [acc[key], input.value]
          };
        }
      }

      return { ...acc, [input.name]: input.value };
    }, {});

    // Send fetch request
    fetch(target.getAttribute('action'), {
      method: target.getAttribute('method')
      // body: JSON.stringify(data)
    })
      .then((data) => data.json())
      .then((data) => {
        if (resultContainer) {
          resultContainer.querySelector('span').innerHTML = data.count;
        }
        if (toursListContainer) {
          toursListContainer.innerHTML = data.response;
        }
      });
  };

  searchForm && searchForm.addEventListener('submit', searchFormHandle);

  // Clear filter
  const resetFilterForms = document.querySelectorAll('.js-clear-filter-form');

  resetFilterForms.length &&
    resetFilterForms.forEach((form) => {
      form.addEventListener('submit', (event) => {
        event.preventDefault();
        const { target } = event;

        const resultContainer = document.querySelector('.catalog-tours__filter-panel-result');
        const toursListContainer = document.querySelector('.tours-list');

        fetch(target.getAttribute('action'), {
          method: target.getAttribute('method')
        })
          .then((data) => data.json())
          .then((data) => {
            if (resultContainer) {
              resultContainer.querySelector('span').innerHTML = data.count;
            }
            if (toursListContainer) {
              toursListContainer.innerHTML = data.response;
            }
          });
      });
    });
  // Clear filter end
  // Catalog search box end

  // SEARCH end =================================================================

  // =========== Common calls
  // Set root props
  setVhProp();
  // Set root props

  // scrollHeader
  scrollHeader();
  // scrollHeader end

  // Tour page fixed slider
  fixedBlockHandle('[data-fixed-block-container]', 1151);
  // Tour page fixed slider end

  // Search handle call
  searchHandle('[data-search-form]');
  // Search handle call
  // =========== Common calls end

  // =========== Common listeners
  // Scroll
  window.addEventListener('scroll', () => {
    setVhProp();
    scrollHeader();
    fixedBlockHandle('[data-fixed-block-container]', 1151);
  });

  // Resize
  window.addEventListener('resize', () => {
    setVhProp();
    scrollHeader();
    fixedBlockHandle('[data-fixed-block-container]', 1151);
  });
  // =========== Common listeners end
});
